<template>
  <div>
    <ul class="explan-list">
      <li>情報をご提供くださった方には、当館からご連絡を差し上げる場合があります。恐れ入りますがご協力をお願いいたします。</li>
      <li>ご提供くださった情報の一部は、著作権関連の手続き以外に、当館のウェブサービスで公開させていただく場合があります。（著作者の読み仮名、生没年、肩書及び情報源等。ただし、書誌データに採録されない著作者の情報、（著作者以外の）著作権者や情報提供者の情報は除く。）</li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.explan-list {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
.explan-list li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 5px;
}
.explan-list li::before {
  position: absolute;
  content: url('../../../../assets/dms/list-disc.svg');
  left: 2px;
}
</style>
