
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AuthorInfoVue from '@/components/organisms/dms/AuthorInput/AuthorInfo.vue'
import AuthorNotification from '@/components/organisms/dms/AuthorInput/AuthorNotification.vue'
import UserInfo from '@/components/organisms/dms/AuthorInput/UserInfo.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'
import { AuthorInfo, ContactInfo, CollectAuthor } from '@/data/dms/dmsData'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'AuthorInput',
  components: {
    AuthorInfoVue,
    UserInfo,
    AuthorNotification,
    AppButton,
    ToPageTop,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const requiredCheck = ref(false)
    const authorInfoData = store.getters.collectAuthorData.authorInfo ? ref(store.getters.collectAuthorData.authorInfo) : ref<AuthorInfo>({
      authorId: String(route.query.authorId),
      authorName: '',
      authorInfo: '',
      infoSource: '',
    })
    const contactInfoData = store.getters.collectAuthorData.contactInfo ? ref(store.getters.collectAuthorData.contactInfo) : ref<ContactInfo>({
      name: '',
      mailAddress: '',
      address: '',
      phoneNumber: '',
    })

    const setCollectAuthorData = async (collectAuthorData: CollectAuthor) => {
      await store.dispatch('setCollectAuthorData', collectAuthorData)
    }
    const packAutoCollectDataData = async () => {
      const collectAuthorData = {
        authorInfo: authorInfoData.value,
        contactInfo: contactInfoData.value,
      } as CollectAuthor
      await setCollectAuthorData(collectAuthorData)
    }

    const searchAuthor = async () => {
      const url = `${BASE_URL}/outer/inquiry/_search`
      const body = JSON.stringify({
        authorId: route.query.authorId,
        from: 0,
        keyword: '',
        ndc: '',
        size: 1,
        sortKey: 'authorName',
        sortOrder: 'asc',
      })
      const response = await postDataCredential(url, body, true)
      authorInfoData.value.authorName = response.data.hits.hits[0]._source.authorName
    }
    searchAuthor()

    return {
      router,
      requiredCheck,
      authorInfoData,
      contactInfoData,

      async confirm () {
        requiredCheck.value = true
        await window.fetch('')
        const elements = document.getElementsByClassName('text-alert')
        if (elements.length !== 0) {
          window.scrollTo({
            left: elements[0].getBoundingClientRect().left,
            top: elements[0].getBoundingClientRect().top,
            behavior: 'smooth',
          })
          return
        }
        await packAutoCollectDataData()
        await router.push('/dms/author/confirm')
      },
      inputClear () {
        authorInfoData.value = {
          authorId: authorInfoData.value.authorId,
          authorName: authorInfoData.value.authorName,
          authorInfo: '',
          infoSource: '',
        }
        contactInfoData.value = {
          name: '',
          mailAddress: '',
          address: '',
          phoneNumber: '',
        }
      },
    }
  },
})
