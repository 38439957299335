
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import FormInput from '@/components/organisms/dms/common/FormInput.vue'
import ConfirmInput from '@/components/organisms/dms/common/ConfirmInput.vue'

export default defineComponent({
  name: 'AuthorInfo',
  components: {
    FormInput,
    ConfirmInput,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  setup (props, { emit }) {
    const state = computed({
      get: () => props.modelValue,
      set: (state) =>
        emit('update:modelValue', state),
    })
    return {
      state,
    }
  },
})
